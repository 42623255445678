import "./Profile.css";
import logo from "../../Assets/logo.png";

function MyProfile() {
  return (
    <div className="profile-container">
      <div className="content">
        <h1>Welcome to Clean Connect's CMS</h1>
        <img src={logo} alt="Clean Connect Logo" className="p-logo" />
        <p>Your Cleaning Professionals</p>
      </div>
    </div>
  );
}

export default MyProfile;
