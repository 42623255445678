// SingleChatCard.js
import React, { useState } from "react";
import "./SingleChatCard.css";
import ChatModal from "./ChatModal";
import EndChatModal from "./EndChatModal/EndChatModal";

function SingleChatCard({ selectedChat, setSelectedChat, fetchData }) {
  const [showChatModal, setShowChatModal] = useState(true);
  const [showEndChatModal, setShowEndChatModal] = useState(false);
  const [endChatButton, setEndChatButton] = useState(false);

  const id = localStorage.getItem("ID");

  const toggleChatModal = () => {
    setShowChatModal(!showChatModal);
  };

  const endChat = () => {
    setShowEndChatModal(true);
  };

  const chatEndShow = (val) => {
    setEndChatButton(val);
  };

  console.log(endChatButton);

  return (
    <div className="single-chat-card">
      {showChatModal && (
        <div>
          <ChatModal
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            chatEndShow={chatEndShow}
          />
          {selectedChat !== 0 &&
            selectedChat.active === true &&
            endChatButton &&
            selectedChat.created_by === id && (
              <div className="end-chat-div" onClick={endChat}>
                End Chat
              </div>
            )}
          <EndChatModal
            isOpen={showEndChatModal}
            onClose={() => {
              setShowEndChatModal(false);
            }}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            fetchData={fetchData}
          />
        </div>
      )}
    </div>
  );
}

export default SingleChatCard;
