import React, { useState } from "react";
import "./ChatList.css";
import PRIMARY from "../../Theme/palette";
import FilterCom from "../../Componants/Filters/Communication/Filter";
import ChatsTable from "../../Componants/Tables/TableCom/ChatsTable";

function ChatList() {
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");

  return (
    <>
      <div className="chat-list-topic">
        <p>Active Chat List</p>
        <button
          className="btn btn-primary"
          style={{
            border: "none",
            backgroundColor: PRIMARY.darkGray,
            padding: "0 20px",
            color: PRIMARY.white,
            fontWeight: "500",
          }}
        >
          Chat History
        </button>
      </div>
      <div>
        <FilterCom
          setFilterName={setFilterName}
          setFilterSite={setFilterSite}
          setFilterSiteAdd={setFilterSiteAdd}
        />
        <ChatsTable
          filterName={filterName}
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
        />
      </div>
    </>
  );
}

export default ChatList;
