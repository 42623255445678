import React, { useState } from "react";
import "./SiteInformation.css";
import FilterSiteInfo from "../../Componants/Filters/SiteInformation/Filter";
import TableSiteinfo from "../../Componants/Tables/TableSite/Table";
import AuditModal from "../../Componants/Modal/AuditModal/AuditModal";

function SiteInformation() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");
  const userType = localStorage.getItem("userType");

  return (
    <>
      <div className="cleaner-topic">
        <p>Site Information </p>
        {userType === "SuperAdmin" && (
          <button onClick={handleShow} className="add-button">
            Add Audit +
          </button>
        )}
      </div>
      <div>
        <FilterSiteInfo
          setFilterName={setFilterName}
          setFilterSite={setFilterSite}
          setFilterSiteAdd={setFilterSiteAdd}
          filterSite={filterSite}
        />
        <TableSiteinfo
          filterName={filterName}
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
        />
        <AuditModal show={show} setShow={setShow} Title="create" />
      </div>
    </>
  );
}

export default SiteInformation;
