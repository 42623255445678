import React, { useState, useEffect } from "react";
import "./ToolboxReaders.css";
import ToolboxReader from "../../Componants/Toolbox/ToolboxReader";
import { useParams } from "react-router-dom";
import { getReaderDetailsbyID } from "../../API/axios";

function ToolReader() {
  const { id } = useParams();
  const [siteToolReaders, setsiteToolReaders] = useState([]);

  const GetReaderDetailsbyID = async (id) => {
    try {
      const Data = await getReaderDetailsbyID(id);
      setsiteToolReaders(Data.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetReaderDetailsbyID(id);
  }, [id]);

  return (
    <div>
      <div className="cleaner-topic">
        <p>PDF Document</p>
      </div>
      <div>
        <ToolboxReader docId={id} siteToolReaders={siteToolReaders} />
      </div>
    </div>
  );
}

export default ToolReader;
