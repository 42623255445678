import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./TaskInformation.css";
import { gettaskByID, gettaskCommentByID } from "../../API/axios";

function TaskInformation() {
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [senderUserName, setSenderUserName] = useState("");
  const [senderSiteAddress, setSenderSiteAddress] = useState("");
  const [receiverFirstName, setReceiverFirstName] = useState("");
  const [receiverLastName, setReceiverLastName] = useState("");
  const [deadline, setDeadline] = useState("");
  const [task_tittle, setTask_tittle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [comment, setComment] = useState("");
  const [created_date, setCreated_date] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSiteDetails = () => {
      gettaskByID(id)
        .then((response) => {
          const siteData = response.tasks[0];

          var deadlineDate = siteData.deadline;

          if (deadlineDate && deadlineDate.length >= 10) {
            var trimmedDeadlineDate = deadlineDate.substring(0, 10);
          } else {
            console.log("Invalid Deadline date format");
          }

          setDeadline(trimmedDeadlineDate);
          setSenderUserName(siteData.senderUserName);
          setSenderSiteAddress(siteData.senderSiteAddress);
          setReceiverFirstName(siteData.receiverFirstName);
          setReceiverLastName(siteData.receiverLastName);
          setTask_tittle(siteData.task_tittle);
          setDescription(siteData.description);
          setPriority(siteData.priority);
          setCreated_date(siteData.created_date.substring(0, 10));
          setImage(siteData.image);
          console.log(siteData.senderUserName);
        })
        .catch((error) => {
          console.log(error);
        });

      //

      gettaskCommentByID(id)
        .then((response) => {
          setComment(response.comments);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    getSiteDetails();
  }, [id]);

  return (
    <>
      <div className="cleaner-topic">
        <p>Task Information</p>
      </div>
      <div className="site-info-container">
        <div className="left-container">
          <div className="upper-left">
            <h5>Image</h5>
            {image ? (
              <object
                data={image}
                type="application/pdf"
                width="100%"
                height="100%"
              ></object>
            ) : (
              <span>No image!</span>
            )}
          </div>
          <div className="lower-left">
            <h5>Comments</h5>

            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Description</th>
                  <th
                    scope="col"
                    style={{
                      width: "130px",
                      minWidth: "130px",
                      verticalAlign: "top",
                    }}
                  >
                    Image
                  </th>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {loading ? (
                    <tr>
                      <td colSpan="5">Loading...</td>
                    </tr>
                  ) : comment.length > 0 ? (
                    comment.map((item) => (
                      <tr key={item.id}>
                        <td>{item.comment_description}</td>
                        <td>
                          {" "}
                          <img
                            src={
                              item.comment_image
                                ? item.comment_image
                                : "No image!"
                            }
                            alt=""
                            className="manualLogImg"
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No data</td>
                    </tr>
                  )}
                </React.Fragment>
              </tbody>
            </table>
          </div>
        </div>
        <div className="right-container">
          <Form.Label>Client : {senderUserName}</Form.Label>
          <br></br>
          <Form.Label>Site : {senderSiteAddress}</Form.Label>
          <br></br>
          <Form.Label>Task Title : {task_tittle}</Form.Label>
          <br></br>
          <Form.Label>Assignee : {receiverFirstName}</Form.Label>
          <br></br>
          <Form.Label>Deadline : {deadline}</Form.Label>
          <br></br>

          <Form.Label>
            Priority : {priority === 1 && "High"}
            {priority === 2 && "Medium"}
            {priority === 3 && "Low"}
          </Form.Label>
          <br></br>

          <Form.Label>Task Description : {description}</Form.Label>
          <br></br>
        </div>
      </div>
    </>
  );
}
export default TaskInformation;
