import React, { useState, useEffect } from "react";
import "./ToolboxReader.css";
import { getDocumentDetailsbyID } from "../../API/axios";

function SiteManualReader({ docId, siteToolReaders }) {
  const [Documentpath, setDocumentpath] = useState("");
  const [loading, setLoading] = useState(true);

  const GetDocumentDetailsbyID = async (id) => {
    try {
      const Data = await getDocumentDetailsbyID(id);
      const docData = Data.data.data[0];
      setDocumentpath(docData.document_link);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  console.error("siteToolReaders:", siteToolReaders);

  useEffect(() => {
    GetDocumentDetailsbyID(docId);
  }, [docId]);

  return (
    <>
      <div className="manual-wrapper">
        <div className="manual">
          <div className="pdf-view">
            <iframe
              title="Sample PDF"
              src={Documentpath}
              width="100%"
              height="500"
            />
          </div>
          <div className="readers">
            <div className="cleaner-topic">
              <h5>Readers List</h5>
            </div>
            <br></br>
            <div className="readerDetail">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Emp No</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Signed Date</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {loading ? (
                      <tr>
                        <td colSpan="5">Loading...</td>
                      </tr>
                    ) : siteToolReaders.length > 0 ? (
                      siteToolReaders.map((item) => (
                        <tr key={item.id}>
                          <td>{item.emp_no}</td>
                          <td>{item.user_name}</td>
                          <td>{item.signed_at}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Readers..</td>
                      </tr>
                    )}
                  </React.Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteManualReader;
