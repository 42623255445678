import React, { useState } from "react";
import "./SiteManual.css";
import FilterSiteManual from "../../Componants/Filters/Site-Manual/Filter";
import TableSiteManual from "../../Componants/Tables/Tablesitemanual/Table";
import NewSiteManualDocument from "./NewSiteManualDocument";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";

function SiteManual() {
  const [filterSiteAdd, setFilterSiteAdd] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filterSite, setFilterSite] = useState("");
  const userType = localStorage.getItem("userType");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Site Manual </p>
        {userType === "SuperAdmin" && (
          <button onClick={() => setShowModal(true)} className="add-button">
            Add New +
          </button>
        )}
      </div>
      <div>
        <FilterSiteManual
          setFilterSite={setFilterSite}
          filterSite={filterSite}
          setFilterSiteAdd={setFilterSiteAdd}
        />
        <TableSiteManual
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
        />
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <NewSiteManualDocument
              handleCloseModal={handleCloseModal}
              setShowModal={setShowModal}
              showModal={showModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default SiteManual;
