import React from "react";
import Modal from "react-bootstrap/Modal";
import CreateForm from "./CreateForm";

function CleanerModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title === "create" ? "Create Cleaner" : "Update Cleaner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateForm
            title={props.Title}
            show={props.show}
            setShow={props.setShow}
            item={props.item}
            handleClose={handleClose}
            GetAllCleaners={props.GetAllCleaners}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CleanerModal;
