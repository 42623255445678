import React, { useState, useEffect } from "react";
import "./Layout.css";
import {
  FaBuilding,
  FaClipboardCheck,
  FaClock,
  FaCogs,
  FaUser,
  FaUsers,
  FaSignOutAlt,
  FaSatellite,
  FaTasks,
  FaAngleRight,
} from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Sidebar() {
  const [showMyProfileSubMenu, setshowMyProfileSubMenu] = useState(false);
  const [showDailyRecordSubMenu, setshowDailyRecordSubMenu] = useState(false);
  const [showOperationSubMenu, setshowOperationSubMenu] = useState(false);
  const [showDashboardMenu, setshowDashboardMenu] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();

  const toggleMyProfileSubMenu = () => {
    setshowMyProfileSubMenu(!showMyProfileSubMenu);
  };
  const toggleDashboardMenu = () => {
    setshowDashboardMenu(!showDashboardMenu);
  };

  const toggleDailyRecordSubMenu = () => {
    setshowDailyRecordSubMenu(!showDailyRecordSubMenu);
  };

  const toggleOperatioSubMenu = () => {
    setshowOperationSubMenu(!showOperationSubMenu);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const [userRole, setUserRole] = useState("");
  const [AccessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    // Retrieve user role from local storage
    const roleFromStorage = localStorage.getItem("userType");
    setUserRole(roleFromStorage);
  }, []);

  // Check if userRole is 'client' to decide whether to render ChangedPassword
  const canAccess = userRole !== "Client";

  useEffect(() => {
    setAccessDenied(!canAccess);
  }, [canAccess]);

  const handleHelpClick = () => {
    toast.info("This feature is still under development.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div className="sidebar-full-wrapper">
      <div className="navigation">
        {canAccess && (
          <div style={{ cursor: "pointer" }}>
            <NavLink
              className="navLink"
              activeClassName="active"
              to="/profile"
              onClick={toggleMyProfileSubMenu}
            >
              <FaUser style={{ margin: "0 20px 0 10px" }} />
              My Profile
            </NavLink>
            {showMyProfileSubMenu && (
              <ul>
                <li className="subMenu">
                  <NavLink className="subMenuItem" to="/changepassword">
                    <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                    Change Password
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        )}
        {AccessDenied && (
          <div style={{ cursor: "pointer" }}>
            <NavLink className="navLink" activeClassName="active" to="/profile">
              <FaUser style={{ margin: "0 20px 0 10px" }} />
              My Profile
            </NavLink>
          </div>
        )}

        {canAccess && (
          <NavLink className="navLink" activeClassName="active" to="/mysites">
            <FaBuilding style={{ margin: "0 20px 0 10px" }} /> My Clients &
            Sites
          </NavLink>
        )}
        {/* {AccessDenied && (
          <NavLink className="navLink" activeClassName="active" to="/mysites">
            <FaBuilding style={{ margin: "0 20px 0 10px" }} /> My Sites
          </NavLink>
          // You can replace this with any component or message you want to display
        )} */}

        <div style={{ cursor: "pointer" }}>
          <NavLink
            className="navLink"
            activeClassName="active"
            to="/mycleaner"
            onClick={toggleDailyRecordSubMenu}
          >
            <FaUsers style={{ margin: "0 20px 0 10px" }} />
            My Cleaners
          </NavLink>
          {showDailyRecordSubMenu && (
            <ul>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/dailyrecords">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  My Areas & Cleaners
                </NavLink>
              </li>
            </ul>
          )}
        </div>

        <NavLink className="subMenuItem" to="/assigntasks">
          <FaTasks style={{ margin: "0 20px 0 10px" }} />
          Tasks
        </NavLink>

        <li className="subMenu">
          <NavLink className="subMenuItem" to="/operationsui">
            <FaClipboardCheck style={{ margin: "0 20px 0 10px" }} />
            Real Time Data
          </NavLink>
        </li>

        <NavLink
          className="navLink"
          activeClassName="active"
          to="/timeonsiteprogress"
        >
          <FaClock style={{ margin: "0 20px 0 10px" }} />
          Time On Site Progress
        </NavLink>

        <div style={{ cursor: "pointer" }}>
          <div
            className="navLink"
            activeClassName="active"
            onClick={toggleOperatioSubMenu}
          >
            <FaSatellite style={{ margin: "0 20px 0 10px" }} />
            Operations
          </div>
          {showOperationSubMenu && (
            <ul>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/communication">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Communication
                </NavLink>
              </li>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/incidentreport">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Incident Report
                </NavLink>
              </li>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/recommendation">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Recommendation
                </NavLink>
              </li>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/siteinformation">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Site Information
                </NavLink>
              </li>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/toolboxtalk">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Tool Box Talk
                </NavLink>
              </li>
              <li className="subMenu">
                <NavLink className="subMenuItem" to="/sitemanual">
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Site Manual
                </NavLink>
              </li>

              <li className="subMenu">
                <NavLink
                  className="navLink"
                  activeClassName="active"
                  to="/myorders"
                >
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} /> My Orders
                </NavLink>
              </li>

              <li className="subMenu">
                <div className="subMenuItem" onClick={handleHelpClick}>
                  <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                  Help
                </div>
              </li>
            </ul>
          )}
        </div>

        {canAccess && (
          <div style={{ cursor: "pointer" }}>
            <NavLink
              className="navLink"
              activeClassName="active"
              to="/dashboard"
              onClick={toggleDashboardMenu}
            >
              <FaCogs style={{ margin: "0 20px 0 10px" }} />
              Dashboard
            </NavLink>
            {showDashboardMenu && (
              <ul>
                <li className="subMenu">
                  <NavLink className="subMenuItem" to="/clientsummary">
                    <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                    Client Summary
                  </NavLink>
                </li>
                <li className="subMenu">
                  <NavLink className="subMenuItem" to="/clientprogress">
                    <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                    Client Progress
                  </NavLink>
                </li>
                <li className="subMenu">
                  <NavLink className="subMenuItem" to="/cleanersummary">
                    <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                    Cleaner Summary
                  </NavLink>
                </li>
                <li className="subMenu">
                  <NavLink className="subMenuItem" to="/stocksummary">
                    <FaAngleRight style={{ margin: "0 20px 0 10px" }} />
                    Stock Summary
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        )}

        <div className="navLink" onClick={() => setLogoutModal(true)}>
          <FaSignOutAlt style={{ margin: "0 20px 0 10px" }} /> Logout
        </div>
      </div>
      <div className="copyright">
        @{new Date().getFullYear()} Your Cleaning Professionals
      </div>

      {logoutModal && (
        <Modal
          style={{ background: "rgba(15, 14, 14, 0.144)" }}
          show={logoutModal}
          onHide={() => setLogoutModal(false)}
        >
          <Modal.Header className="text-center">
            <div className="d-flex justify-content-center align-items-center text-danger">
              <FaExclamationCircle size={24} style={{ marginLeft: "220px" }} />
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center ">
            Are you sure you want to Logout ?
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="secondary" onClick={() => setLogoutModal(false)}>
              No
            </Button>
            <Button variant="dark" onClick={handleLogout}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Sidebar;
