import React, { useState } from "react";
import "./ChatCard.css";
import { FaTrash } from "react-icons/fa";
import DeleteChatModal from "./DeleteChatModal/DeleteChatModal";
import Badge from "react-bootstrap/Badge";

const userType = localStorage.getItem("userType");
const id = localStorage.getItem("ID");

function ChatCard({
  title,
  name,
  address,
  chatHistory,
  deleteChat,
  chat,
  unReadCount,
  senderID,
}) {
  const [show, setShow] = useState(false);
  const admin = localStorage.getItem("Admin");
  console.log(admin);
  const deletehandler = () => {
    deleteChat(chat.id);
    setShow(false);
  };

  const showDeleteModal = () => {
    setShow(true);
  };
  const hideDeleteModal = () => {
    setShow(false);
  };
  return (
    <div className="chat-card">
      <DeleteChatModal
        isOpen={show}
        onClose={hideDeleteModal}
        deletehandler={deletehandler}
      />
      <div className="chat-card-notification">
        <div className="card-title">{title}</div>
        {senderID != id && senderID != undefined && unReadCount != 0 ? (
          <div className="chat-card-notification-circle">
            <Badge bg="secondary">{unReadCount}</Badge>
          </div>
        ) : null}
      </div>

      <div
        className={
          chatHistory || admin == "superAdmin"
            ? "card-name-history"
            : "card-name"
        }
      >
        {chatHistory || admin == "superAdmin" ? (
          <button className="delete-button" onClick={showDeleteModal}>
            {userType === "SuperAdmin" && <FaTrash className="delete-icon" />}
          </button>
        ) : null}
        {admin === "superAdmin" || name !== "Super Admin"
          ? `Client : ${name}, Site : ${address}`
          : null}
      </div>
    </div>
  );
}

export default ChatCard;
