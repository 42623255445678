import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import PRIMARY from "../../../Theme/palette";
import { Spinner } from "react-bootstrap";
import "./CreateTaskForm.css";
import {
  GetClients,
  addTask,
  updateTask,
  getCleanerUsersBySiteId,
} from "../../../API/axios";
import "./CreateTaskForm.css";

function CreateTaskForm(props) {
  const [formData, setFormData] = useState({});
  const [cleanerusers, setCleanerUsers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [showdocument, setshowdocument] = useState(null);
  const [sites, setAllSites] = useState([]);
  const userId = localStorage.getItem("ID");
  const userType = localStorage.getItem("userType");
  const userName = localStorage.getItem("UserNameVal");
  var valselectedSiteid = "";
  useState(false);

  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        const response = await GetClients();
        if (response.status === 200) {
          const data = response.data;
          setAllSites(data.sites);
        } else {
          console.error("Failed to fetch data: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSitesData();

    if (props.title === "update") {
      GetCleanerUsers(props.item.sender);
      var deadlineDate = props.item.deadline;

      if (deadlineDate && deadlineDate.length >= 10) {
        var trimmedDeadlineDate = deadlineDate.substring(0, 10);
      } else {
        console.log("Invalid Deadline date format");
      }

      setshowdocument(props.item.image);
      setFormData({
        task_title: props.item.task_tittle,
        task_description: props.item.description,
        task_date: trimmedDeadlineDate,
        fileupload: props.item.image,
        selectedPriority: props.item.priority,
        selectedClient: props.item.senderUserName,
        selectedSite: props.item.sender,
        selectedAssignee: props.item.receiver,
      });
    } else {
      setFormData({
        task_title: "",
        task_description: "",
        task_date: "",
        fileupload: null,
        selectedPriority: "",
        selectedClient: "",
        selectedSite: "",
        selectedAssignee: "",
      });
    }
  }, [props.title, props.item]);

  const [selectedId, setSelectedId] = useState("");

  const GetCleanerUsers = async (selectedIdval) => {
    try {
      const Data = await getCleanerUsersBySiteId(selectedIdval);
      setCleanerUsers(Data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedId) {
      const GetCleanerUsers = async () => {
        try {
          const Data = await getCleanerUsersBySiteId(selectedId);
          setCleanerUsers(Data.result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      GetCleanerUsers();
    }
  }, [selectedId]);

  useEffect(() => {
    if (userType === "Client") {
      const GetCleanerUsers = async () => {
        try {
          const Data = await getCleanerUsersBySiteId(userId);

          setCleanerUsers(Data.result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      GetCleanerUsers();
    }
  }, [userId]);

  const handleCategoryChange = (e) => {
    setCleanerUsers("");
    setSelectedId(e.target.value);
    const { name, value } = e.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClientChange = (e) => {
    setSelectedId("");
    setCleanerUsers("");
    const { name, value } = e.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const uniqueClientNames = Array.from(
    new Set(
      sites.map((item) => ({
        site_id: item.site_id,
        site_name: item.site_name,
      }))
    )
  );

  const uniqueSiteNames = Array.from(
    new Set(
      sites
        .filter((items) => items.site_name === formData.selectedClient)
        .map((item) => ({
          site_id: item.site_id,
          site_address: item.site_address,
        }))
    )
  );

  const uniqueSiteNamesbyloginName = Array.from(
    new Set(
      sites
        .filter((items) => items.site_name === userName)
        .map((item) => ({
          site_id: item.site_id,
          site_address: item.site_address,
        }))
    )
  );

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const validateForm = async () => {
    const errors = {};
    if (userType === "SuperAdmin") {
      valselectedSiteid = formData.selectedSite;
    } else if (userType === "Client") {
      valselectedSiteid = userId;
    }

    console.log("zz", valselectedSiteid);
    if (props.title === "update") {
      if (!valselectedSiteid.trim()) {
        errors.selectedSite = "Site Name is required.";
      }
      if (!formData.task_title.trim()) {
        errors.task_title = "Task update Title is required.";
      }
      if (!formData.task_description.trim()) {
        errors.task_description = "Task Description is required.";
      }
      if (!formData.selectedAssignee.trim()) {
        errors.selectedAssignee = "Task Assignee is required.";
      }
      if (!formData.task_date.trim()) {
        errors.task_date = "Task Deadline is required.";
      }
    } else {
      if (userType === "SuperAdmin") {
        if (!formData.selectedClient.trim()) {
          errors.selectedClient = "Client Name is required.";
        }
      }
      if (!valselectedSiteid.trim()) {
        errors.selectedSite = "Site Name is required.";
      }
      if (!formData.task_title.trim()) {
        errors.task_title = "Task Title is required.";
      }
      if (!formData.task_description.trim()) {
        errors.task_description = "Task Description is required.";
      }
      if (!formData.selectedPriority.trim()) {
        errors.selectedPriority = "Task Priority is required.";
      }
      if (!formData.selectedAssignee.trim()) {
        errors.selectedAssignee = "Task Assignee is required.";
      }
      if (!formData.task_date.trim()) {
        errors.task_date = "Task Deadline is required.";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);
    try {
      const isValid = await validateForm();

      if (isValid) {
        setisButtonLoading(true);

        if (userType === "SuperAdmin") {
          valselectedSiteid = formData.selectedSite;
        } else if (userType === "Client") {
          valselectedSiteid = userId;
        }

        if (props.title === "update") {
          const updateRequestBody = {
            id: props.item.id,
            sender: valselectedSiteid,
            receiver: formData.selectedAssignee,
            deadline: formData.task_date,
            description: formData.task_description,
            task_tittle: formData.task_title,
            priority: formData.selectedPriority,
            image:
              typeof formData.document === "string"
                ? "null"
                : formData.document,
          };
          updateTask(updateRequestBody)
            .then((response) => {
              if (response.status) {
                props.handleClose();
                props.setShow(!props.show);
                toast.success("Task updated successfully!");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error(error.response.data.message);
              setisButtonLoading(false);
              setIsDisabled(false);
            });
        } else {
          const createRequestBody = {
            sender: valselectedSiteid,
            receiver: formData.selectedAssignee,
            deadline: formData.task_date,
            description: formData.task_description,
            task_tittle: formData.task_title,
            priority: formData.selectedPriority,
            image: formData.document,
          };
          addTask(createRequestBody)
            .then((response) => {
              if (response.status) {
                props.handleClose();
                props.setShow(!props.show);
                toast.success("Task Inserted successfully!");
              }
            })
            .catch((error) => {
              console.log("test");
              console.error(error.response.data.message);
              toast.error(error.response.data.message);
              setIsDisabled(false);
            });
        }
      } else {
        setIsDisabled(false);
      }
    } catch (error) {
      // Handle validation error or submission error
      setIsDisabled(false);
    }
  };

  const renderOptions = () => {
    if (userType === "SuperAdmin") {
      return uniqueClientNames.map((name) => (
        <option key={name.site_name} value={name.site_name}>
          {name.site_name}
        </option>
      ));
    } else {
      return <option selected>No Client to Select</option>; // Render nothing if neither superadmin nor client
    }
  };

  const siteaddOptions = () => {
    if (userType === "SuperAdmin") {
      return uniqueSiteNames.map((name) => (
        <option key={name.site_id} value={name.site_id}>
          {name.site_address}
        </option>
      ));
    } else if (userType === "Client") {
      return (
        <option selected key={userId} value={userId}>
          {userName}
        </option>
      );
    } else {
      return <option selected>No Site to Select</option>; // Render nothing if neither superadmin nor client
    }
  };

  const siteusrOptions = () => {
    if (userType === "SuperAdmin") {
      {
        cleanerusers ? (
          cleanerusers.map((item, index) => {
            return (
              <option key={item.user_id} value={item.user_id} selected>
                {item.f_name}
              </option>
            );
          })
        ) : (
          <option selected>No Assign to Select</option>
        );
      }
    } else if (userType === "Client") {
    } else {
      return <option selected>No Client to Select</option>; // Render nothing if neither superadmin nor client
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <div className="filter-wrapper p-3">
          <Row>
            {userType === "SuperAdmin" && (
              <Col>
                <Form.Group>
                  <Form.Label>Client:</Form.Label>
                  <Form.Select
                    id="selectedClient"
                    name="selectedClient"
                    value={formData.selectedClient}
                    onChange={handleClientChange}
                  >
                    <option value="">Select a Client</option>
                    {renderOptions()}
                  </Form.Select>

                  {validationErrors.selectedClient && (
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "2px 0",
                        margin: "0",
                      }}
                      className="text-danger"
                    >
                      {validationErrors.selectedClient}
                    </p>
                  )}
                </Form.Group>
              </Col>
            )}
            {userType === "SuperAdmin" && (
              <Col>
                <Form.Group>
                  <Form.Label>Site:</Form.Label>
                  <Form.Select
                    name="selectedSite"
                    value={formData.selectedSite}
                    onChange={handleCategoryChange}
                  >
                    <option selected>Select Site</option>
                    {siteaddOptions()}
                  </Form.Select>
                  {validationErrors.selectedSite && (
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "2px 0",
                        margin: "0",
                      }}
                      className="text-danger"
                    >
                      {validationErrors.selectedSite}
                    </p>
                  )}
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="Taskheaders">Task Title:</Form.Label>

                <Form.Control
                  type="text"
                  name="task_title"
                  value={formData.task_title}
                  onChange={handleInputChange}
                />

                {validationErrors.task_title && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.task_title}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="Taskheaders">
                  Task Description:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="task_description"
                  value={formData.task_description}
                  onChange={handleInputChange}
                />
                {validationErrors.task_description && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.task_description}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="Taskheaders">Priority:</Form.Label>
                <Form.Select
                  name="selectedPriority"
                  value={formData.selectedPriority}
                  onChange={handleInputChange}
                >
                  <option value="" selected>
                    Select Priority
                  </option>
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </Form.Select>
                {validationErrors.selectedPriority && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.selectedPriority}
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label className="Taskheaders">Assignee:</Form.Label>
                <Form.Select
                  className="form-select"
                  name="selectedAssignee"
                  value={formData.selectedAssignee}
                  onChange={handleInputChange}
                  id="selectedAssignee"
                  disabled={!cleanerusers}
                >
                  <option value="">Select Assign User</option>
                  {cleanerusers ? (
                    cleanerusers.map((item, index) => {
                      return (
                        <option
                          key={item.user_id}
                          value={item.user_id}
                          selected
                        >
                          {item.f_name}
                        </option>
                      );
                    })
                  ) : (
                    <option selected>No Assign to Select</option>
                  )}
                </Form.Select>
                {validationErrors.selectedAssignee && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.selectedAssignee}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Group>
              <Form.Label className="Taskheaders">Deadline:</Form.Label>
              <Form.Control
                type="date"
                min={new Date().toISOString().split('T')[0]}
                name="task_date"
                value={formData.task_date}
                onChange={handleInputChange}
                style={{ width: "150px" }}
              />
              {validationErrors.task_date && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.task_date}
                </p>
              )}
            </Form.Group>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            {!showdocument ? (
              <> </>
            ) : (
              <>
                <div>
                  <br></br>
                  <strong>Selected Document: </strong>
                  <object
                    data={formData.fileupload}
                    type="application/pdf"
                    width="70%"
                  ></object>
                  <br></br>
                </div>
              </>
            )}
            <Form.Group>
              <Form.Label>Upload Document:</Form.Label>
              <Form.Control
                type="file"
                name="document"
                onChange={handleFileChange}
                accept=".jpeg,.png,.jpg"
              />
              {validationErrors.document && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.document}
                </p>
              )}
            </Form.Group>
          </Row>
        </div>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default CreateTaskForm;
