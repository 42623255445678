import React, { useState, useEffect } from "react";
import "./SiteManualReaders.css";
import SiteManualReader from "../../Componants/SiteManual/SiteManualReader";
import { useParams } from "react-router-dom";
import { getSiteManualReaders } from "../../API/axios";

function SiteManualReaders() {
  const { id } = useParams();
  const {siteID } = useParams();
  
  const [siteManualReaders, setsiteManualReaders] = useState([]);

  useEffect(() => {
    getSiteManualReaders(siteID, id)
      .then((response) => {
        //console.log("readers", response.refered);
        setsiteManualReaders(response.refered);
      })
      .catch((error) => {
        console.error(error);
      });
  },[]);


  return (
    <div>
      <div className="cleaner-topic">
        <p>PDF Document</p>
      </div>
      <div>
        <SiteManualReader siteManualReaders={siteManualReaders} id={id}/>
      </div>
    </div>
  );
}

export default SiteManualReaders;
