import React, { useEffect, useState } from "react";
import "./Livetable.css";
import { getAllTaskTraking } from "../../API/axios";
import { Pagination, Table } from "react-bootstrap";

function SiteTable(props) {
  const [trakingTasks, setTrakingTasks] = useState([]);
  const [filteredTrakingTask, setFilteredTrakingTask] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  const GetAllTaskTraking = async () => {
    try {
      const Data = await getAllTaskTraking();
      setTrakingTasks(Data.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetAllTaskTraking();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = trakingTasks.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;
      const fNameMatch =
        props.filterFname === "" || item.f_name === props.filterFname;
      const lNameMatch =
        props.filterLname === "" || item.l_name === props.filterLname;

      const datePickerStartDate = props.filterStartDate
        ? new Date(props.filterStartDate).toISOString().split("T")[0]
        : "";
      const startDate = item.start_time
        ? new Date(item.start_time).toISOString().split("T")[0]
        : "";
      const startDayMatch =
        props.filterStartDate === null ||
        props.filterStartDate === "" ||
        !item.start_time ||
        startDate >= datePickerStartDate;

      const datePickerEndDate = props.filterEndDate
        ? new Date(props.filterEndDate).toISOString().split("T")[0]
        : "";

      const endDateMatch =
        props.filterEndDate === null ||
        props.filterEndDate === "" ||
        !item.start_time ||
        startDate <= datePickerEndDate;
      //console.log(`Item start_at: ${item.start_time}, Filter Start Date: ${props.filterStartDate}, Filter End Date: ${props.filterEndDate}, Start Day Match: ${startDayMatch}, End Date Match: ${endDateMatch}`);
      return (
        clientMatch &&
        siteMatch &&
        fNameMatch &&
        lNameMatch &&
        startDayMatch &&
        endDateMatch
      );
    });

    setFilteredTrakingTask(filteredData);
    setFilterChanged(true);
  }, [
    props.filterClient,
    props.filterSite,
    props.filterFname,
    props.filterLname,
    props.filterStartDate,
    props.filterEndDate,
    trakingTasks,
  ]);

  const totalPages = Math.ceil(filteredTrakingTask.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTrakingTask = filteredTrakingTask.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return "--";
    }
    const time = new Date(timeString);
    const formattedDate = time.toISOString().split("T")[0];
    const formattedTime = time.toISOString().split("T")[1].split(".")[0];
    return `${formattedDate} ${formattedTime}`;
  };

  const formatWork = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    return `${minutes}`;
  };

  return (
    <div
      className="table-container"
      style={
        {
          // overflowX: "auto",
          // maxHeight: "500px",
          // overflowY: "auto",
          // maxWidth: "1300px",
        }
      }
    >
      {/* <table className="table" style={{ margin: "10px 20px" }}></table> */}
      <Table responsive>
        <thead className="thead-light">
          <tr>
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "100px",
                  minWidth: "100px",
                  verticalAlign: "top",
                }}
              >
                Client
              </th>
            )}
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "100px",
                  minWidth: "100px",
                  verticalAlign: "top",
                }}
              >
                Site Key
              </th>
            )}
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "150px",
                  minWidth: "150px",
                  verticalAlign: "top",
                }}
              >
                Site
              </th>
            )}
            <th
              scope="col"
              style={{
                width: "200px",
                minWidth: "200px",
                verticalAlign: "top",
              }}
            >
              Cleaner Name
            </th>
            <th
              scope="col"
              style={{
                width: "130px",
                minWidth: "130px",
                verticalAlign: "top",
              }}
            >
              Login Server Receive Time{" "}
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Login GPS{" "}
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Login Status{" "}
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Unsuccessful Login
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Photo Match Ratio
            </th>
            <th
              scope="col"
              style={{
                width: "130px",
                minWidth: "130px",
                verticalAlign: "top",
              }}
            >
              Logout Server Receive Time
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Logout GPS
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Logout Status{" "}
            </th>
            <th
              scope="col"
              style={{
                width: "130px",
                minWidth: "130px",
                verticalAlign: "top",
              }}
            >
              Forgot logout Server Receive Time
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Unsuccessful Logout
            </th>
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                verticalAlign: "top",
              }}
            >
              Photo Match Ratio
            </th>
            <th
              scope="col"
              style={{
                width: "100px",
                minWidth: "100px",
                verticalAlign: "top",
              }}
            >
              TOS(min)
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentTrakingTask.length > 0 ? (
            currentTrakingTask.map((item) => (
              <tr key={item.record_id}>
                {!isClient && <td>{item.site_name}</td>}
                {!isClient && <td>{item.site_no}</td>}
                {!isClient && (
                  <td title={item.site_address}>
                    {truncateSiteName(item.site_address)}
                  </td>
                )}
                <td>
                  {item.f_name} {item.l_name}
                </td>
                <td>{formatTime(item.start_time)}</td>
                <td style={{ color: item.login_dist > 200 ? "red" : "black" }}>
                  {item.login_dist}
                </td>
                <td>{item.login_auth}</td>
                <td>
                  <img src={item.login_image} alt="" className="manualLogImg" />
                </td>
                <td>{item.img_ratio}</td>
                <td>{formatTime(item.end_time)}</td>
                {/* <td>
                  {item.logout_dist < 1000 ? (
                    item.end_gps
                  ) : (
                    <span>
                      {item.end_gps}{" "}
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        Cleaner not in site
                      </span>
                    </span>
                  )}
                </td> */}
                <td style={{ color: item.logout_dist > 200 ? "red" : "black" }}>
                  {item.logout_dist}
                </td>
                <td>{item.logout_auth}</td>
                <td>{formatTime(item.forget_end_at)}</td>
                <td>
                  <img
                    src={item.logout_image}
                    alt=""
                    className="manualLogImg"
                  />
                </td>
                <td>{item.logout_img_ratio}</td>
                <td>{formatWork(item.total_work)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
      </Table>
      {currentTrakingTask.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default SiteTable;
